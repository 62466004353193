import React from "react";

const InstagramIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-30-Copy" transform="translate(-1396.000000, -120.000000)">
        <g id="Group-15" transform="translate(12.000000, 0.000000)">
          <g id="Group-94-Copy" transform="translate(1384.000000, 120.000000)">
            <path
              d="M12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
              id="Rectangle-Copy-4"
              fill="#C53189"
            ></path>
            <g id="instagram" transform="translate(7.506849, 7.500000)" fill="#FFFFFF" fill-rule="nonzero">
              <path
                d="M5.50340794,3.19647244 C6.14044912,3.83351363 6.14044912,4.86636675 5.50340794,5.50340794 C4.86636675,6.14044912 3.83351363,6.14044912 3.19647244,5.50340794 C2.55943125,4.86636675 2.55943125,3.83351363 3.19647244,3.19647244 C3.83351363,2.55943125 4.86636675,2.55943125 5.50340794,3.19647244"
                id="Path"
              ></path>
              <path
                d="M6.253125,0 L2.446875,0 C1.09758656,0 0,1.09758656 0,2.446875 L0,6.253125 C0,7.60241344 1.09758656,8.7 2.446875,8.7 L6.253125,8.7 C7.60241344,8.7 8.7,7.60241344 8.7,6.253125 L8.7,2.446875 C8.7,1.09758656 7.60241344,0 6.253125,0 Z M4.35,6.525 C3.15072131,6.525 2.175,5.54953969 2.175,4.35 C2.175,3.15046031 3.15072131,2.175 4.35,2.175 C5.54927869,2.175 6.525,3.15046031 6.525,4.35 C6.525,5.54953969 5.54927869,6.525 4.35,6.525 Z M6.796875,2.175 C6.64673312,2.175 6.525,2.05326688 6.525,1.903125 C6.525,1.75294995 6.64673312,1.63125 6.796875,1.63125 C6.94701688,1.63125 7.06875,1.75294995 7.06875,1.903125 C7.06875,2.05326688 6.94701688,2.175 6.796875,2.175 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InstagramIcon;

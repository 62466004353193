/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { array } from "prop-types";
import { Link } from "@quintype/components";

import SocialShareIcons from "../../molecules/social-share";

import Button from "../../atoms/Button";
import AppStore from "../../atoms/app-store";
import GooglePlay from "../../atoms/google-play";
import ExapandIcon from "../../atoms/icons/expand-icon";

import FooterCollection from "./footer-collection";

import "./footer.m.css";

const FooterBase = ({ footerMenus, footerTags, footerSections, footerCollection }) => {
  const [showSection, setShowSection] = useState(false);

  const toggleSectionHandler = (idx) => {
    setShowSection(showSection === idx ? undefined : idx);
  };

  return (
    <div>
      <div styleName="row1">
        {footerSections.map((item, index) => (
          <div styleName="row1-list" key={index}>
            <div styleName="title-icon">
              <Link key={index} externalLink={item.isExternalLink} href={item && item?.url}>
                <div styleName="title">{item.title}</div>
              </Link>
              {item.children.length > 0 && (
                <div
                  key={index}
                  styleName={showSection === index ? "down-icon rotate-icon" : "down-icon"}
                  onClick={() => toggleSectionHandler(index)}
                  className="hide-desktop"
                >
                  <ExapandIcon />
                </div>
              )}
            </div>

            <div styleName={`collapse ${showSection === index ? "active" : ""}`}>
              {item.children?.map((i, index) => (
                <Link key={index} externalLink={item.isExternalLink} href={i.url}>
                  <div styleName="list-item-text">{i.title}</div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div styleName="row2">
        <div styleName="button-wrapper">
          {footerTags.map((item, index) => {
            return item.isExternalLink ? (
              <a href={item.completeUrl} target="_blank" rel="noreferrer">
                <Button styleName="btn" key={index}>
                  <p styleName="btn-title">{item.title}</p>
                </Button>
              </a>
            ) : (
              <Link key={index} href={item.completeUrl}>
                <Button styleName="btn">
                  <p styleName="btn-title">{item.title}</p>
                </Button>
              </Link>
            );
          })}
        </div>
        <FooterCollection collection={footerCollection} />
      </div>
      <div styleName="row3">
        <div styleName="footer-links">
          {footerMenus.map((item, index) => {
            const idx = index + 1;
            return (
              <div key={index} style={{ display: "flex" }}>
                <div styleName="footer-item">
                  {item["item-type"] === "tag" ? (
                    <Link key={index} href={`/tags/${item["tag-slug"]}`}>
                      {item.title}
                    </Link>
                  ) : (
                    <a key={index} href={item?.url} target="_blank" rel="noreferrer">
                      {item.title}
                    </a>
                  )}
                </div>
                {idx > 0 && idx % 5 === 0 && idx !== footerMenus.length && <div styleName="line"></div>}
                {idx > 0 && idx % 5 !== 0 && idx !== footerMenus.length && <span styleName="dot" />}
              </div>
            );
          })}
        </div>
        <div styleName="social-share-icons">
          <SocialShareIcons />
          <div styleName="app-icons">
            <a
              href="https://play.google.com/store/apps/details?id=com.tpml.pv&hl=en_IN&gl=US&pli=1"
              target="_blank"
              styleName="app-link"
              rel="noreferrer"
            >
              <GooglePlay />
            </a>
            <a
              href="https://apps.apple.com/in/app/prajavani-kannada-news-app/id1535764933"
              target="_blank"
              styleName="app-link"
              rel="noreferrer"
            >
              <AppStore />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    footerMenus: get(state, ["qt", "data", "navigationMenu", "footer"], []),
    footerTags: get(state, ["qt", "data", "navigationMenu", "footerTags"], []),
    footerSections: get(state, ["qt", "data", "navigationMenu", "footerSections"], []),
    footerCollection: get(state, ["qt", "data", "footerCollection"], {})
  };
}

FooterBase.propTypes = {
  footerMenus: array,
  footerTags: array,
  footerSections: array
};

export const Footer = connect(mapStateToProps, null)(FooterBase);

import React from "react";
import PT from "prop-types";

import "./hamburger-menu.m.css";

export const HamburgerMenu = ({ onMenuToggle, isMegaMenuOpen, isMobileHamburger }) => (
  <button
    aria-label="Hamburger Menu"
    className="hamburger-menu"
    onClick={onMenuToggle}
    styleName={`hamburger ${isMegaMenuOpen ? "is-open" : ""}`}
  >
    {[1, 2, 3].map((i) => (
      <span styleName="line" key={i} style={{ backgroundColor: !isMegaMenuOpen && isMobileHamburger && "white" }} />
    ))}
  </button>
);

HamburgerMenu.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool,
  isMobileHamburger: PT.bool,
};

export default HamburgerMenu;

import React from "react";
import { HeaderDesktopAd } from "../../../ads";

import "./header-ad.m.css";

const DesktopHeaderAd = () => (
  <div styleName="wrapper" className="hide-mobile">
    <HeaderDesktopAd />
  </div>
);

export default DesktopHeaderAd;

import React, { useState } from "react";
import { Link } from "@quintype/components";

import ExapandIcon from "../../../atoms/icons/expand-icon";
import { filterCollection, filterStory } from "../../../helper/api";

import "./footer-collection.m.css";

const FooterCollection = ({ collection }) => {
  if (!collection.items) return null;

  const [showSection, setShowSection] = useState(false);
  const collections = filterCollection(collection);

  const showSectionHandler = (idx) => {
    setShowSection(showSection === idx ? undefined : idx);
  };

  return (
    <div styleName="footer-collections">
      {collections.map((collection, index) => {
        const stories = filterStory(collection);

        return (
          <div key={index} styleName="collection-item">
            <div styleName="collection-title">
              <Link href={`/${collection.slug}`} styleName="footer-collection-href">
                {collection.name}
              </Link>
              <div
                key={index}
                styleName={showSection === collection.id ? "down-icon rotate-icon" : "down-icon"}
                className="hide-desktop"
                onClick={() => showSectionHandler(collection.id)}
              >
                <ExapandIcon />
              </div>
            </div>

            {showSection === collection.id && (
              <div className="hide-desktop">
                {stories?.splice(0, 4).map((story, index) => (
                  <Link href={`/${story.slug}`} key={index}>
                    <h3 key={index}>{story.headline}</h3>
                  </Link>
                ))}
              </div>
            )}

            <div styleName="collapse" className="hide-mobile">
              {stories?.splice(0, 4).map((story, index) => (
                <Link href={`/${story.slug}`} key={index}>
                  <h3 key={index}>{story.headline}</h3>
                </Link>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FooterCollection;

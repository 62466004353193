import React, { useState } from "react";
import { bool, array, object, string } from "prop-types";
import { Link } from "@quintype/components";
import get from "lodash/get";
import { connect } from "react-redux";
import { SvgIconHandler } from "./atoms/svg-icon-hadler";

import "./breaking-news.m.css";

const BREAKING_NEWS = "ಈ ಕ್ಷಣ :";

const renderBreakingNewsMarquee = (breakingNews, breakingNewsConfig, handleMouseEnter, handleMouseLeave, isHovered) => {
  const items = breakingNews.map((story) => {
    const linkedStorySlug = get(story, ["metadata", "linked-story-slug"], null);
    const externalLink = breakingNewsConfig.open_in_new_tab ? `/${linkedStorySlug}` : false;
    const linkLabel = `Read full story: ${story.headline}`;

    if (linkedStorySlug) {
      return (
        <Link
          aria-label={linkLabel}
          key={story.id}
          styleName="item"
          href={`/${linkedStorySlug}`}
          externalLink={externalLink}
        >
          {story.headline}
        </Link>
      );
    }

    return (
      <div key={story.id} styleName="item">
        {story.headline}
      </div>
    );
  });

  return (
    <>
      <div
        styleName={`marquee-wrapper `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ "--items": breakingNews.length }}
      >
        <div styleName={`marquee-container ${isHovered ? "paused" : ""} `}>{items}</div>
        <div styleName={`marquee-container marquee-cont ${isHovered ? "paused" : ""} `}>{items}</div>
      </div>
    </>
  );
};
export const BreakingNewsViewBase = ({ breakingNews = [], breakingNewsConfig = {}, pageType }) => {
  const [isHovered, setIsHovered] = useState(false);
  const breakingNewsCount = breakingNewsConfig.item_display;
  const breakingNewsItem = breakingNewsCount ? breakingNews.slice(0, breakingNewsCount) : breakingNews;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div styleName="base">
      <div styleName="container">
        <div styleName="breaking-news-label">
          <SvgIconHandler type="breakingnews" height="28px" width="28px" />
          <span>{BREAKING_NEWS}</span>
        </div>
        {renderBreakingNewsMarquee(breakingNewsItem, breakingNewsConfig, handleMouseEnter, handleMouseLeave, isHovered)}
      </div>
    </div>
  );
};

BreakingNewsViewBase.propTypes = {
  breakingNewsLoaded: bool,
  breakingNews: array,
  breakingNewsConfig: object,
  pageType: string
};

const mapStateToProps = (state) => ({
  pageType: get(state, ["qt", "pageType"], null),
  breakingNewsConfig: get(state, ["qt", "config", "publisher-attributes", "breaking_news"], {})
});

export const BreakingNewsView = connect(mapStateToProps, () => ({}))(BreakingNewsViewBase);

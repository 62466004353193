import React from "react";

const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <g fill="none" fillRule="evenodd">
      <path fill="#32529F" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M10.46 17.15v-4.315H9.007v-1.716h1.451V9.762c0-.716.202-1.273.604-1.668.402-.396.936-.594 1.602-.594.54 0 .98.025 1.32.075v1.527h-.905c-.34 0-.572.075-.698.226-.1.126-.15.327-.15.603v1.188h1.602l-.227 1.716h-1.375v4.316h-1.773z"
      />
    </g>
  </svg>
);

export default FacebookIcon;

import React from "react";
import { Link } from "@quintype/components";
import LogoDate from "../../../atoms/logo-date";
import { NavBar } from "../nav-bar";

import "./pv-header.m.css";

const PvHeader = () => {
  return (
    <>
      <div styleName="main-wrapper">
        <div styleName="logo-and-ad">
          <div styleName="logo">
            <LogoDate />
            <Link href="/">
              <img src="/icons/lstlogo.png" styleName="pv-logo" />
            </Link>
          </div>
        </div>
      </div>
      <header id="nav-bar" className="sticky-header">
        <NavBar />
      </header>
    </>
  );
};

export { PvHeader };

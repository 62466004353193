import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { DfpComponent } from "./dfp-component";
import useDevice from "../helper/custom-hook/useDevice";
import { WithLazy } from "@quintype/components";

import "./ads.m.css";

const AdSlot = ({ id, ad_type, adStyleName = "", isExternalAdPath = false }) => {
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const networkId = get(qtState, ["config", "ads-config", "network_id"], "");
  const adConfig = get(qtState, ["config", "ads-config", "slots", ad_type], {});
  const { sizes, ad_unit, view_port_size_mapping, externalPath } = adConfig;
  const adUnitPath = isExternalAdPath ? externalPath : `${networkId}/${ad_unit}`;

  const DfpComponentWrapper = () => (
    <DfpComponent
      adStyleName={adStyleName}
      id={id}
      path={adUnitPath}
      size={sizes}
      viewPortSizeMapping={view_port_size_mapping}
      type="top-ad"
    />
  );

  if (ad_unit === "PV_MWeb_AP_Mid" || ad_unit === "PV_Desktop_AP_Mid") {
    return <DfpComponentWrapper />;
  }
  return <WithLazy>{() => <DfpComponentWrapper />}</WithLazy>;
};

const AdContainer = React.memo(
  ({ id, desktop, mobile, AdClassName = "", showText = true, isExternalAdPath = false }) => {
    return (
      <div styleName="ad-container">
        {showText ? (
          <span styleName="ad-text" className="ad-text">
            ADVERTISEMENT
          </span>
        ) : null}

        <div className="ad-background" styleName={`ad-background ${AdClassName}`}>
          {global.width >= 768 && desktop ? (
            <div styleName="desktop">
              <AdSlot id={`${id}`} ad_type={desktop} isExternalAdPath={isExternalAdPath} />
            </div>
          ) : null}

          {global.width <= 768 && mobile ? (
            <div styleName="mobile">
              <AdSlot id={`${id}`} ad_type={mobile} isExternalAdPath={isExternalAdPath} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

const HeaderDesktopAd = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], "home-page"));
  const adsId = {
    // "home-page": "div-gpt-ad-1675836701321-0",
    // "story-page": "div-gpt-ad-1675837096352-0",
    "izooto-top": "div-gpt-ad-1711630530156-0"
  };
  const ads = {
    // "home-page": "PV_Desktop_HP_Display_Leaderboard_Top",
    // "story-page": "PV_Desktop_AP_Leaderboard_Top",
    "izooto-top": "PV_Desktop_AP_LB_Top_ym"
  };

  const [adType, setAdType] = useState("");

  useEffect(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const utmSource = searchParams.get("utm_source");
      if (utmSource === "izooto") {
        setAdType("izooto");
      }
    } catch (e) {}
  }, []);

  return null;
  // <div>
  //   <AdContainer
  //     AdClassName="standard-970x90"
  //     id={adType === "izooto" ? adsId["izooto-top"] : adsId[pageType] || "div-gpt-ad-1675836701321-0"}
  //     desktop={adType === "izooto" ? ads["izooto-top"] : ads[pageType] || "PV_Desktop_HP_Display_Leaderboard_Top"}
  //     isExternalAdPath={adType === "izooto" ? true : false}
  //   />
  // </div>
};

const OutOfPageAd = () => {
  const isMobile = useDevice();
  return (
    <>
      {isMobile ? (
        <AdContainer
          className="hide-desktop"
          showText={false}
          id="div-gpt-ad-1675937438395-0"
          mobile="PV_Mobile_Interstial"
        />
      ) : (
        <AdContainer
          className="hide-mobile"
          showText={false}
          id="div-gpt-ad-1675937467341-0"
          desktop="PV_Desktop_Interstial"
        />
      )}
    </>
  );
};

export { AdSlot, AdContainer, HeaderDesktopAd, OutOfPageAd };

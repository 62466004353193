import React from "react";

const YoutubeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <g fill="none" fillRule="evenodd">
      <path fill="#DC242F" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" />
      <g fillRule="nonzero">
        <path
          fill="#FFF"
          d="M17.287 9.03c-.32-.568-.667-.672-1.373-.712-.705-.048-2.48-.068-4.016-.068-1.54 0-3.315.02-4.02.067-.704.04-1.051.144-1.374.714-.33.567-.499 1.545-.499 3.267v.006c0 1.714.17 2.7.499 3.261.323.569.67.672 1.374.72.705.04 2.48.065 4.02.065 1.537 0 3.31-.024 4.017-.065.706-.048 1.053-.15 1.372-.72.332-.561.5-1.546.5-3.26v-.006c0-1.723-.168-2.7-.5-3.268z"
        />
        <path fill="#B82831" d="M10.424 14.51v-4.42l3.681 2.21z" />
      </g>
    </g>
  </svg>
);

export default YoutubeIcon;

import React from "react";
import { format } from "date-fns";
import { kn } from "date-fns/locale";
import "./logo-date.m.css";

const LogoDate = () => {
  const date = format(new Date(), "eeee, d MMMM yyyy", { locale: kn }).replace("ಫೆಬ್ರವರಿ", "ಫೆಬ್ರುವರಿ");
  return <div styleName="date">{date}</div>;
};

export default LogoDate;

import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";

import "./menu-item.m.css";

const MenuItem = ({
  item,
  toggleHandler,
  menuStyle = "menu-link",
  changeTextColor = false,
  changeTextWeight = false
}) => {
  if (item["item-type"] === "placeholder") {
    return (
      <button styleName="menu-link-wrapper">
        <span
          id="item-type placeholder"
          styleName="menu-link menu-span"
          style={{
            color: `${changeTextColor ? item.data.color : ""}`,
            fontWeight: `${changeTextWeight ? "bold" : ""}`
          }}
        >
          {item.title}
        </span>
      </button>
    );
  } else {
    return (
      <Link
        styleName={menuStyle}
        aria-label="menu-item"
        callback={toggleHandler}
        className={menuStyle}
        href={item?.url ? item.url : "/"}
        externalLink={item.isExternalLink && item?.url}
        style={{
          color: `${changeTextColor ? item.data.color : ""}`,
          fontWeight: `${changeTextWeight ? "bold" : ""}`
        }}
      >
        {item.title}
      </Link>
    );
  }
};

MenuItem.propTypes = {
  item: PT.object,
  showIcon: PT.bool
};

export { MenuItem };

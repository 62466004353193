import React, { useState } from "react";
import { string, object } from "prop-types";
import get from "lodash/get";
import { useSelector } from "react-redux";

import { AdContainer } from "../../ads";
import "./sticky-banner.m.css";
import useAdType from "../../helper/custom-hook/useAdType";

const StickyBanner = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"]));
  const [showAd, setShowAd] = useState(true);
  const adType = useAdType();

  const adsId = {
    "story-page": "div-gpt-ad-1675837571080-0",
    "home-page": "div-gpt-ad-1675837460755-0",
    "izooto-sticky": "div-gpt-ad-1711631305087-0"
  };

  const ads = {
    "story-page": "PV_Mweb_AP_Sticky",
    "home-page": "PV_Mweb_HP_Sticky",
    "izooto-sticky": "PV_Mweb_AP_Sticky_ym"
  };

  return (
    <div styleName="wrapper">
      {showAd && (
        <div styleName="banner">
          <div styleName="cross-btn" onClick={() => setShowAd(false)}>
            &#xd7;
          </div>
          <div styleName="ad-wrapper">
            <span styleName="ad-text">ADVERTISEMENT</span>
            <div styleName="sticky-ad">
              <AdContainer
                showText={false}
                id={adType === "izooto" ? adsId["izooto-sticky"] : adsId[pageType] || "div-gpt-ad-1675837460755-0"}
                mobile={adType === "izooto" ? ads["izooto-sticky"] : ads[pageType] || "PV_Mweb_HP_Sticky"}
                isExternalAdPath={adType === "izooto" ? true : false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StickyBanner.propTypes = {
  pageType: string,
  story: object
};

export default StickyBanner;

export const OPEN_HAMBURGER_MENU = "OPEN_HAMBURGER_MENU";
export const OPEN_SEARCHBAR = "OPEN_SEARCHBAR";
export const MEMBER_UPDATED = "MEMBER_UPDATED";
export const IS_OPEN_LOGIN_FORM = "IS_OPEN_LOGIN_FORM";

// Action to enable/disable to loading indicator
export function setLoading(isLoading) {
  return isLoading ? { type: "QT-PAGE_LOADING" } : { type: "QT-PAGE_FINISHED_LOADING" };
}

export const setMoreStories = (payload) => {
  return {
    type: "MORE_STORIES",
    payload
  };
};

export const setMakeSecondaryApiCallsTriggered = (makeSecondaryApiCalls, delay, isMobile) => {
  return {
    type: "SET_MAKE_SECONDARY_API_CALLS_TRIGGERED",
    makeSecondaryApiCalls: makeSecondaryApiCalls,
    delay: delay,
    isMobile: isMobile
  };
};

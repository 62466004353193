import React from "react";

const TwitterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="6" width="20" height="18" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM13.6864 15.7104L7.80569 7.84729H12.3381L16.1934 13.0022L20.965 7.84729H22.2968L16.7885 13.798L23.0001 22.1034H18.4677L14.2815 16.5062L9.10043 22.1034H7.76855L13.6864 15.7104ZM11.8465 8.82835H9.76432L18.959 21.1222H21.0412L11.8465 8.82835Z"
      fill="black"
    />
  </svg>
);

export default TwitterIcon;

import React from "react";
import { object } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import FacebookIcon from "../../atoms/social-share-icons/facebook";
import InstagramIcon from "../../atoms/social-share-icons/instagram";
import TwitterIcon from "../../atoms/social-share-icons/twitter";
import YoutubeIcon from "../../atoms/social-share-icons/youtube";

import "./social-share.m.css";

const SocialShareIcons = ({ socialLinks, socials }) => {
  if (!socialLinks) {
    return null;
  }

  const list = [
    {
      Type: <FacebookIcon isShareRequired={false} />,
      url: socials && socials.facebook ? socials.facebook.url : socialLinks["facebook-url"],
      name: "Facebook"
    },
    {
      Type: <TwitterIcon isShareRequired={false} />,
      url: socials && socials.twitter ? socials.twitter.url : socialLinks["twitter-url"],
      name: "Twitter"
    },
    {
      Type: <YoutubeIcon isShareRequired={false} />,
      url: socials && socials.youtube ? socials.youtube.url : socialLinks["youtube-url"],
      name: "Youtube"
    },
    {
      Type: <InstagramIcon isShareRequired={false} />,
      url: socials && socials.instagram ? socials.instagram.url : socialLinks["instagram-url"],
      name: "Instagram"
    }
  ];

  return (
    <div styleName="wrapper" className="social-icons-wrapper">
      {list.map(({ url, Type, name }, index) => (
        <a
          href={url}
          key={index}
          styleName="social-link-item"
          className="social-link-item"
          target="_blank"
          rel="nofollow noreferrer"
        >
          {Type}
        </a>
      ))}
    </div>
  );
};

SocialShareIcons.propTypes = {
  socialLinks: object,
  socials: object
};

const mapStateToProps = (state) => ({
  socialLinks: get(state, ["qt", "config", "social-links"], {})
});

export default connect(mapStateToProps, null)(SocialShareIcons);

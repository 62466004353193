import get from "lodash/get";

const getStoryTemplate = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "story-template"], "text");
  } else {
    return "";
  }
};

const getSectionSlug = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "slug"], "NA");
  } else if (pageType === "section-page" || pageType === "sub-section-page") {
    return get(state, ["data", "section", "slug"], "NA");
  }
  return "NA";
};

const getSectionId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "id"], "NA");
  } else if (pageType === "section-page" || pageType === "sub-section-page") {
    return get(state, ["data", "section", "id"], "NA");
  }
  return "NA";
};

const getStoryId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "public-identifier"], "NA");
  }
  return "NA";
};

const getSectionList = (state, pageType) => {
  if (pageType === "story-page") {
    const sections = get(state, ["data", "story", "sections"], []);
    const sectionList = sections.map((item) => item.name);
    return sectionList;
  }

  return [];
};

const getTagList = (state, pageType) => {
  if (pageType === "story-page") {
    const tags = get(state, ["data", "story", "tags"], []);
    const tagList = tags.map((item) => item.name);
    return tagList;
  }

  return [];
};

export const setViewportSizeMapping = (adSlot, googletag, viewPortSizeMapping) => {
  if (!viewPortSizeMapping.length) return;

  const mapping = googletag
    .sizeMapping()
    .addSize(viewPortSizeMapping[0].viewport, viewPortSizeMapping[0].sizes)
    .addSize(viewPortSizeMapping[1].viewport, viewPortSizeMapping[1].sizes)
    .build();

  adSlot.defineSizeMapping(mapping);
};

export const generateLazyLoading = (googletag, fetchMarginPercent, renderMarginPercent, mobileScaling) => {
  const updateSlotStatus = (slotId, state) => {
    const elem = document.getElementById(slotId + "-" + state);
    if (elem) {
      elem.className = "activated";
      elem.innerText = "Yes";
    }
  };

  if (googletag && googletag.pubadsReady) {
    // detect whether PubAdsService is fully loaded
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent, // Fetch slots within specified viewports
      renderMarginPercent, // Render slots within specified viewports
      mobileScaling // Multiplies the specified value with the above values for mobile
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "fetched");
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "rendered");
    });
  }
};

export const setTargetingParams = (googletag, adSlot, qtState, storySectionSlug) => {
  const pageType = get(qtState, ["pageType"], "");
  const environment = get(qtState, ["config", "publisher-attributes", "env"], "");
  const currentPath = get(qtState, ["currentPath"], "");
  const sectionSlug = storySectionSlug || getSectionSlug(qtState, pageType);
  const sectionId = getSectionId(qtState, pageType);
  const storyId = getStoryId(qtState, pageType);
  const sectionList = getSectionList(qtState, pageType);
  const tagList = getTagList(qtState, pageType);
  const storyTemplate = getStoryTemplate(qtState, pageType);

  adSlot
    .addService(googletag.pubads())
    .setTargeting("pageType", pageType)
    .setTargeting("environment", environment)
    .setTargeting("currentPath", currentPath)
    .setTargeting("sectionSlug", sectionSlug)
    .setTargeting("sectionId", sectionId)
    .setTargeting("storyId", storyId)
    .setTargeting("sectionList", sectionList)
    .setTargeting("tagList", tagList)
    .setTargeting("storyTemplate", storyTemplate)
    .setTargeting("publisherName", "Prajavani");
};

export const useDfpSlot = ({ path, size, id, qtState, viewPortSizeMapping, storySectionSlug, refreshAdUnit }) => {
  if (refreshAdUnit && window.googletag && window.googletag.apiReady) {
    // check if the API is ready
    window.googletag.cmd.push(function () {
      if (window.googletag.pubadsReady) {
        // detect whether PubAdsService is fully loaded
        const existingSlot = window.googletag
          .pubads()
          .getSlots()
          .filter((item) => id === item.getSlotElementId());

        window.googletag.pubads().refresh(existingSlot);
      }
    });
  }

  window.googletag.cmd.push(function () {
    const responsiveAdSlot = window.googletag.defineSlot(path, size, id);

    if (responsiveAdSlot) {
      setTargetingParams(window.googletag, responsiveAdSlot, qtState, storySectionSlug);
      if (viewPortSizeMapping) {
        setViewportSizeMapping(responsiveAdSlot, window.googletag, viewPortSizeMapping);
      }
      // eslint-disable-next-line no-undef
      googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
    }
  });

  window.googletag.cmd.push(function () {
    window.googletag.display(id);
  });
};

export const getAdSlots = ({ path, size, id, qtState, type, viewPortSizeMapping, storySectionSlug, refreshAdUnit }) => {
  useDfpSlot({
    path: path,
    size: size,
    id: id,
    qtState: qtState,
    type: type,
    viewPortSizeMapping: viewPortSizeMapping,
    storySectionSlug,
    refreshAdUnit
  });
};

import axios from "axios";

const FIELDS =
  "id,headline,slug,url,hero-image-s3-key,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,author-name,author-id,authors,sections,story-template,cards";

const API_BASE_URL = "/api/v1"; // API base URL here

const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});

export const getCollection = async (slug, offset = 0, limit = 4, id = "") => {
  const params = {
    limit,
    offset,
    "item-type": "story"
  };

  if (id !== "") {
    params["exclude-story-ids"] = id;
  }

  try {
    const response = await axiosInstance.get(`/collections/${slug}`, {
      params
    });

    const items = response.data.items.filter((i) => i.type === "story").map((i) => i.story);
    return items;
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export const getStories = async (offset = 0, limit = 10, params = {}) => {
  const queryParams = {
    limit,
    offset,
    ...params
  };

  try {
    const response = await axiosInstance.get("/stories", {
      params: queryParams
    });

    return response.data.stories;
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export const getAuthors = async (offset = 0, limit = 5) => {
  const queryParams = {
    limit,
    offset
  };

  try {
    const response = await axiosInstance.get("/authors", {
      params: queryParams
    });

    return response.data;
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export const getAuthorStories = async (authorId, offset, limit) => {
  const storyFields =
    "headline,subheadline,seo,slug,url,hero-image-s3-key,hero-image-caption,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,author-name,author-id,sections,story-template,metadata";

  const queryParams = {
    "story-fields": storyFields,
    "item-type": "story",
    offset,
    limit
  };

  try {
    const response = await axiosInstance.get(`/authors/${authorId}/collection`, {
      params: queryParams
    });

    return (response.data.items || []).map((item) => item.story);
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export const getAdvancedSearchStories = async (offset, limit, queryParams) => {
  const storyFields =
    "headline,subheadline,slug,url,hero-image-s3-key,hero-image-caption,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,author-name,author-id,sections,story-template,metadata";

  const queryParamsObject = {
    fields: storyFields,
    offset,
    limit,
    ...queryParams
  };

  try {
    const response = await axiosInstance.get("/advanced-search", {
      params: queryParamsObject
    });

    return response.data.items;
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export const loadStoriesForInfiniteScroll = async (story) => {
  // You can implement this function as needed
  return [];
};

export const getRelatedStories = async (storyId, offset = 0, limit = 5) => {
  const queryParams = {
    limit,
    offset
  };

  try {
    const response = await axiosInstance.get(`/stories/${storyId}/related-stories`, {
      params: queryParams
    });

    return response.data["related-stories"];
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export const filterStory = (collection) => {
  return collection?.items?.filter((item) => item.type === "story").map((item) => item.story);
};

export const filterCollection = (collection) => {
  return collection?.items?.filter((item) => item.type === "collection");
};

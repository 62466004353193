import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { throttle } from "lodash";
import { PvHeader } from "./pv-header";
import { MobileHeader } from "./mobile-header";
import StickyBanner from "../../molecules/sticky-banner";
import { setMakeSecondaryApiCallsTriggered } from "../../store/actions";

const Header = () => {
  const dispatch = useDispatch();
  const makeSecondaryApiCallsDelay = 2750;
  const [makeSecondaryApiCalls, setMakeSecondaryApiCalls] = useState(false);
  const [isMobile, setMobile] = useState(true);

  const getScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 700) {
        !makeSecondaryApiCalls && setMakeSecondaryApiCalls(true);
      }
    }
  };

  useEffect(() => {
    if (window && window.innerWidth < 1025) {
      setMobile(true);
    } else {
      setMobile(false);
      setMakeSecondaryApiCalls(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", throttle(getScroll, 250));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    makeSecondaryApiCalls &&
      dispatch(setMakeSecondaryApiCallsTriggered(makeSecondaryApiCalls, makeSecondaryApiCallsDelay, isMobile));
  }, [makeSecondaryApiCalls]);

  return (
    <>
      <div className="header-hide-tab">
        <PvHeader />
      </div>
      <div className="header-hide-dektop">
        <MobileHeader />
      </div>

      <StickyBanner />
    </>
  );
};

export { Header };
